<template>
  <div id="BatteryManage">
    <Table
      :loading="loading"
      max-height="740"
      :columns="columns"
      :data="Batterydata"
    ></Table>

    <Page
      show-total
      :page-size="limit"
      style="text-align: center; margin-top: 5px"
      :total="total"
      :current="page"
      @on-change="pageChange"
    />
  </div>
</template>

<script>
import { DeviceBatterList, BatterDelete } from '@/utils/javaApi.js'
export default {
  name: 'BatteryManage',
  props: ['teamID'],
  watch: {
    teamID: function (val) {
      this.loadBatteryList()
    },
  },
  data: function () {
    return {
      userType: '',
      userInfo: {},
      Project1info: {},
      columns: [
        {
          title: '电池类型',
          key: 'battertype',
          align: 'center',
        },
        {
          title: '电池序列号',
          key: 'battercode',
          align: 'center',
        },
        {
          title: '总飞行次数',
          key: 'flycount',
          align: 'center',
        },
        // {
        //   title: "总飞行时长",
        //   key: "flytime",
        //   align: "center",
        // },
        {
          title: '循环次数',
          key: 'cyclecount',
          align: 'center',
        },
        {
          title: '电池寿命',
          key: 'batterlife',
          align: 'center',
        },
        {
          title: '最后使用',
          key: 'lastusetime',
          width: 180,
          align: 'center',
        },
        {
          title: '操作',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'Button',
                {
                  props: {
                    type: 'error',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      if (this.userInfo.is_manager === 1) {
                        this.removeBattery(params.row)
                      } else {
                        this.$Message.warning('您没有操作权限')
                      }
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      Batterydata: [],
      loading: false,
      team_id: '',
      limit: 14,
      page: 1,
      total: 0,
    }
  },
  mounted() {
    this.userType = sessionStorage.getItem('userType')
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'))
    this.Project1info = JSON.parse(sessionStorage.getItem('Project1info'))
    this.loadBatteryList()
  },
  methods: {
    //加载电池数据列表
    loadBatteryList() {
      this.loading = true
      this.$post(DeviceBatterList(), {
        team_id: this.teamID,
        page: this.page,
        limit: this.limit,
      }).then((res) => {
        this.Batterydata = []
        this.loading = false
        if (res.code == 1) {
          this.total = res.data.total
          if (res.data.data.length === 0) {
            return
          }
          res.data.data.forEach((item) => {
            let minute = parseInt(item.flytime / 60)
            if (minute > 60) {
              item.flytime = (minute / 60).toFixed(1) + '小时'
            } else {
              item.flytime = minute + '分钟'
            }
            item.batterlife = item.batterlife * 100 + '%'
            this.Batterydata.push(item)
          })
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    // 翻页
    pageChange(ind) {
      this.page = ind
      this.loadBatteryList()
    },
    // 删除
    removeBattery(row) {
      this.$post(BatterDelete(), {
        id: row.id,
      }).then((res) => {
        if (res.code == 1) {
          this.loadBatteryList()
          this.$Message.info(res.msg_customer)
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
#BatteryManage {
  width: 100%;
  height: 100%;
  background-color: #3c3d43;
  overflow: auto;
  box-shadow: 0px 3px 6px #333;
  border-radius: 4px;
  padding: 15px 20px;
  position: relative;
  /deep/ .ivu-table th {
    background: #333;
    color: #fff;
  }
  /deep/ .ivu-table {
    background: unset;
  }
  /deep/ .ivu-table td {
    background: #1e1e1e;
    color: #fff;
  }
  /deep/ .ivu-table td,
  .ivu-table th {
    border-bottom: 1px solid #000;
  }
  /deep/ .ivu-table th {
    border-bottom: 1px solid #000;
  }
}
</style>

